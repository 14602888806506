.btn-prev,
.btn-next {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border-radius: 50%;
  background-color: #fff;
  color: #3e4265 !important;
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(19, 16, 34, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(19, 16, 34, 0.03);
}

.btn-prev:focus,
.btn-next:focus {
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(19, 16, 34, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(19, 16, 34, 0.03);
}

.btn-prev.bg-white,
.btn-next.bg-white {
  color: #3e4265 !important;
}

.btn-prev:not(.bg-transparent):hover,
.btn-next:not(.bg-transparent):hover {
  background-color: #6366f1 !important;
  color: #fff !important;
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99, 102, 241, 0.9) !important;
}

.btn-prev>i,
.btn-next>i {
  font-size: 1.5rem;
}

.btn-prev.btn-sm>i,
.btn-group-sm>.btn-prev.btn>i,
.btn-next.btn-sm>i,
.btn-group-sm>.btn-next.btn>i {
  font-size: 1.25rem;
}

.btn-prev.btn-lg>i,
.btn-group-lg>.btn-prev.btn>i,
.btn-next.btn-lg>i,
.btn-group-lg>.btn-next.btn>i {
  font-size: 1.75rem;
}

.btn-prev.btn-xl>i,
.btn-next.btn-xl>i {
  font-size: 2rem;
}

.swiper-button-disabled,
.swiper-button-disabled:disabled {
  color: rgba(62, 66, 101, 0.5) !important;
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(19, 16, 34, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(19, 16, 34, 0.03) !important;
  opacity: 1;
}

.swiper-button-disabled.shadow-none,
.swiper-button-disabled:disabled.shadow-none {
  box-shadow: none !important;
}

.pagination .swiper-button-disabled,
.pagination .swiper-button-disabled:disabled {
  background-color: transparent !important;
  box-shadow: none !important;
  pointer-events: none;
}

.swiper .btn-prev:not(.position-static),
.swiper .btn-next:not(.position-static) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.swiper .btn-prev {
  left: 1.25rem;
}

.swiper .btn-next {
  right: 1.25rem;
}

.swiper-nav-onhover .btn-prev,
.swiper-nav-onhover .btn-next {
  opacity: 0 !important;
}

.swiper-nav-onhover:hover .btn-prev,
.swiper-nav-onhover:hover .btn-next {
  opacity: 1 !important;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1.25rem;
}

.swiper-pagination-bullet {
  width: 0.3125rem;
  height: 0.3125rem;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border-radius: 50rem;
  background-color: var(--secondaryColor);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--white);
}

.news-item .swiper-pagination-bullet-active {
  background-color: var(--primaryColor);
}

.swiper-item .swiper-pagination-bullet-active {
  background-color: var(--primaryColor);
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  /* bottom: 1.25rem; */
  bottom: 0rem;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet {
  margin-right: 0.4375rem;
  margin-left: 0.4375rem;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  width: 1.5rem;
}

.swiper-vertical>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-vertical {
  flex-direction: column;
  right: 1.25rem;
}

.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-vertical .swiper-pagination-bullet {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
}

.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-bullets.swiper-pagination-vertical .swiper-pagination-bullet-active {
  height: 1.5rem;
}

.page-link.btn-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
}

.swiper-scrollbar {
  background-color: #e2e5f1;
}

.swiper-scrollbar-drag {
  background-color: #a2a6bc;
}

.swiper-vertical>.swiper-scrollbar {
  top: 0;
  height: 100%;
  width: 0.1875rem;
}

.swiper-horizontal>.swiper-scrollbar {
  left: 0;
  width: 100%;
  height: 0.1875rem;
}

.scrollbar-hover .swiper-scrollbar {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.scrollbar-hover:hover .swiper-scrollbar {
  opacity: 1;
}

.swiper-pagination-progressbar {
  background-color: #e2e5f1;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: var(--primaryColor);
}

.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 0.1875rem;
}

.swiper-vertical>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical {
  width: 0.1875rem;
}

.swiper-tabs {
  position: relative;
  height: 100%;
}

.swiper-tab {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.swiper-tab.active {
  position: relative;
  opacity: 1;
}

.from-top,
.from-bottom,
.from-start,
.from-end,
.scale-up,
.scale-down,
.fade-in {
  transition: all 0.4s 0.4s ease-in-out;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.from-top {
  transform: translateY(-2.25rem);
}

.from-bottom {
  transform: translateY(2.25rem);
}

.from-start {
  transform: translateX(-2.25rem);
}

.from-end {
  transform: translateX(2.25rem);
}

.scale-up {
  transform: scale(0.85);
}

.scale-down {
  transform: scale(1.15);
}

.delay-1 {
  transition-delay: 0.5s;
}

.delay-2 {
  transition-delay: 0.7s;
}

.delay-3 {
  transition-delay: 0.9s;
}

.delay-4 {
  transition-delay: 1.1s;
}

.swiper-slide-active .from-top,
.swiper-slide-active .from-bottom {
  transform: translateY(0);
  opacity: 1;
}

.swiper-slide-active .from-start,
.swiper-slide-active .from-end {
  transform: translateX(0);
  opacity: 1;
}

.swiper-slide-active .scale-up,
.swiper-slide-active .scale-down {
  transform: scale(1);
  opacity: 1;
}

.swiper-slide-active .fade-in {
  opacity: 1;
}

.mobile-app-slider {
  max-width: 328px;
  border-radius: 4rem;
}

@media (min-width: 768px) {
  .mobile-app-slider {
    max-width: 100%;
  }

  .mobile-app-slider .swiper-slide {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.8;
  }

  .mobile-app-slider .swiper-slide.swiper-slide-active {
    opacity: 1;
  }
}


div.cover {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

img.cover {
  position: absolute;
  left: -9999px;
  right: -9999px;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
}

.heroSwiper .swiper-pagination {
  bottom: 6.25rem !important;
}

@media (min-width: 1200px) {
  .news-item .swiper-slide {
    /* opacity: .2 !important; */
    transform: scale3d(0.85, 0.85, 1) !important;
    transition: all 0.3s ease-in-out !important;
  }

  .news-item .swiper-slide-next {
    /* opacity: 1 !important; */
    transform: scale3d(1, 1, 1) !important;
    transition: all 0.3s ease-in-out !important;
  }

  .news-item .swiper-slide-prev {
    transform: scale3d(0.85, 0.85, 1) !important;
    transition: all 0.3s ease-in-out !important;
  }
}

.swiper-wrapper {
  align-items: center;
}