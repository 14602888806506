.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0ms !important;
  transition-duration: 0ms !important;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  -o-transition: -o-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.no-transition,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.no-transition {
  transition: none !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition {
  transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0px) !important;
  max-width: none !important;
  max-height: none !important;
  top: 50% !important;
  left: 50% !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-x,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-x {
  transform: scale3d(1, 1, 1) translate3d(-50%, 0, 0px) !important;
  top: 0 !important;
  left: 50% !important;
  max-width: none !important;
  max-height: none !important;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition-y,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition-y {
  transform: scale3d(1, 1, 1) translate3d(0, -50%, 0px) !important;
  top: 50% !important;
  left: 0% !important;
  max-width: none !important;
  max-height: none !important;
}

.lg-icon.lg-zoom-in:after {
  content: '\e311';
}

.lg-actual-size .lg-icon.lg-zoom-in {
  opacity: 0.5;
  pointer-events: none;
}

.lg-icon.lg-actual-size {
  font-size: 20px;
}

.lg-icon.lg-actual-size:after {
  content: '\e033';
}

.lg-icon.lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

.lg-icon.lg-zoom-out:after {
  content: '\e312';
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer[data-lg-slide-type='video'] .lg-zoom-in,
.lg-outer[data-lg-slide-type='video'] .lg-actual-size,
.lg-outer[data-lg-slide-type='video'] .lg-zoom-out, .lg-outer[data-lg-slide-type='iframe'] .lg-zoom-in,
.lg-outer[data-lg-slide-type='iframe'] .lg-actual-size,
.lg-outer[data-lg-slide-type='iframe'] .lg-zoom-out, .lg-outer.lg-first-slide-loading .lg-zoom-in,
.lg-outer.lg-first-slide-loading .lg-actual-size,
.lg-outer.lg-first-slide-loading .lg-zoom-out {
  opacity: 0.75;
  pointer-events: none;
}
